* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #00171f;
  display: flex;
}

.login .loginContainer {
  padding: 20px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #00171f;
  margin-top: -30px;
  /* box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8); */
}

.login .loginContainer label {
  color: white;
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}
.signUpButt {
  padding: 5px 20px;
  color: white;
  outline: none;
  border: 2px solid #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 20px;
  background: transparent;
  transition: 0.4s all ease;
  align-self: "center";
}
.signUpButt:hover {
  background-color: white;
  color: #00171f;
  border-radius: 10px;
  cursor: pointer;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: "yellow";
  color: #00171f;
  letter-spacing: 1px;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: red;
}

.login .loginContainer .errorMsg {
  color: red;
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  /* font-family: 'Open Sans Condensed', sans-serif; */
  font-size: 16px;
}

a {
  text-decoration: none;
  padding: 10px 20px;
  transition-duration: 0.4s;
}

nav {
  background: #00171f;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px auto;
  width: 100%;
}

.Elements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: "100%";
}

.UserLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

@media only screen and (max-width: 2400px) {
  nav {
    background: #00171f;
    height: auto;
    display: flex;
    flex-direction: column;
    position: "relative";
    align-items: center;
    padding: 10px 30px;
    margin-bottom: -50px;
    width: 100%;
    justify-content: space-between;
  }
}

nav a {
  color: #dddddd;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0px 20px;
  border-bottom: 2px solid #00171f;
}

.Elements a:hover {
  color: white;
  border-bottom: 2px solid #fff;
}

.UserLinks a {
  border: 2px solid #00171f;
}

.UserLinks a:hover {
  border: 2px solid #fff;
}

.hero {
  background-color: #00171f;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  align-items: center;
  position: relative;
}

.hero .main-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  position: relative;
  transition-duration: 0.4s;
}

.hero .main-input-container:hover {
  width: 72.5%;
}

.hero h2 {
  font-size: calc(40px + 5vw);
  margin-top: 40px;
  text-align: center;
}

.hero .mainQ {
  background: transparent;
  outline: none;
  border: none;
  color: white;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  width: 90%;
  min-height: 80px;
  position: relative;
  transition-duration: 0.4s;
}

.hero .mainQ:hover {
  border-bottom: 2px solid white;
}

.mainQ[type="text" i] {
  font-size: calc(15px + 1.5vw);
  color: #ffffff;
  width: 90%;
  overflow-wrap: break-word;
}

.hero .mainQ::placeholder {
  color: #cbcbcb;
  font-size: calc(15px + 1.5vw);
  width: 90%;
  overflow-wrap: break-word;
}
.logout {
  padding: 10px 20px;
  background: white;
  border: none;
  font-weight: 600;
  margin-left: 10px;
}
.hero .mainQ::placeholder:active,
.hero .mainQ::placeholder:hover {
  color: white;
}

.hero button {
  border: none;
  color: #00171f;
  font-size: 30px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  transition-duration: 0.4s;
  box-shadow: 0px 1px 15px 3px rgba(255, 255, 255, 0.1);
  margin: 20px 0px 20px 20px;
  text-align: center;
}

.hero button::after {
  content: "\00BB";
  display: block;
  color: #00171f;
  font-size: 50px;
  font-weight: 2000;
}

.hero button:hover {
  cursor: pointer;
  transform: scale(0.95);
  box-shadow: 0px 1px 15px 5px rgba(255, 255, 255, 0.3);
}

.button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 70%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
}
.button-check {
  border: 2px solid #fff;
  border-radius: 10px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-width: 100px;
  margin: 10px 20px;
  position: relative;
}

.button-check h1 {
  margin: 0px 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
}

.button-check input {
  margin: 0px 10px;
  background-color: none;
  color: none;
}

.button-check input[type="checkbox" i] {
  background-color: none;
  appearance: none;
  background: none;
  outline: none;
  display: block;
  height: 20px;
  width: 20px;
  border: 2px solid #cccccc;
  border-radius: 5px;
  position: relative;
  transition-duration: 0.4s;
}

.button-check input[type="checkbox" i]:hover {
  transform: scale(1.05);
}

.button-check input[type="checkbox" i]:checked {
  background-color: none;
  appearance: none;
  background: #cccccc;
  border: none;
  outline: none;
  display: block;
  height: 20px;
  width: 20px;
}

.radio label:before {
  border-radius: 8px;
}
.checkbox label:before {
  border-radius: 3px;
}
@media only screen and (max-width: 775px) {
  .Elements {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    height: auto;
    width: "100%";
  }
  .logout {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 900px) {
  .hero {
    padding: 10px;
  }

  .hero .main-input-container {
    width: 100%;
    flex-direction: column;
  }

  .hero .main-input-container:hover {
    width: 100%;
  }

  .button-container {
    max-width: 100%;
    padding: 0;
  }

  .button-check {
    margin: 5px;
  }

  .hero button {
    width: auto;
    height: auto;
    border-radius: 40px;
    padding: 10px 20px;
  }

  .hero button::after {
    content: "Submit";
    display: block;
    color: #00171f;
    font-size: 20px;
    font-weight: 600;
  }
}

.AllTheQues {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 0px;
  width: 100%;
  margin: 40px 0;
  margin-bottom: 80px;
}

.ques-title {
  font-size: calc(20px + 2.5vw);
  text-align: center;
  margin-bottom: 20px;
}

.table {
  border-collapse: collapse;
  width: 90%;
  border-radius: 10px;
}

.table tr,
.table td {
  padding: 15px 20px;
}

.table th {
  padding: 10px 20px;
  text-align: center;
  background-color: #00171f;
  color: #ddd;
  border: 2px solid #eee;
  font-size: 20px;
  font-weight: 450;
}

.table td {
  background-color: #eee;
  color: #00171f;
  border-bottom: 2px solid #00171f;
  font-size: 18px;
}

.info-p {
  width: calc(100px + 45vw);
  position: relative;
}

.info-p p {
  display: inline;
  font-weight: 600;
  padding: 0px 10px;
  position: relative;
  border-bottom: 2px solid rgba(140, 143, 146, 0.7);
  transition: 0.4s;
  font-size: 18px;
}

.info-p p:hover {
  cursor: pointer;
  border-bottom: 2px solid #00171f;
}

.hide {
  display: all;
}

.hideAgain {
  display: all;
}

@media only screen and (max-width: 1400px) {
  .hide {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .hideAgain {
    display: none;
  }
}

.scoreboard-container {
  background-color: #00171f;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 40px;
  text-align: center;
  min-height: 100vh;
  position: relative;
}

.scoreboard-info {
  background-color: transparent;
}

.scoreboard-info h1 {
  font-size: calc(40px + 5vw);
  margin-top: 40px;
  text-align: center;
  color: white;
}

.scoreboard-info p {
  font-size: calc(10px + 1vw);
  color: white;
  font-weight: 500;
}

.scoreboard {
  margin-top: 30px;
  border-collapse: collapse;
  width: 90%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.scoreboard tr,
.scoreboard td {
  padding: 15px 20px;
}

.scoreboard th {
  padding: 10px 20px;
  text-align: center;
  background-color: #00171f;
  color: #ddd;
  border: 2px solid #eee;
  font-size: 20px;
  font-weight: 450;
  position: relative;
}

.scoreboard th div {
  display: block;
}

.scoreboard th div p {
  font-size: 20px;
  margin: 0 auto;
  max-width: 160px;
}

.scoreboard-buttons {
  background: none;
  float: right;
  display: block;
  justify-self: flex-start;
}

.scoreboard-buttons button {
  background-color: none;
  color: #00171f;
  outline: none;
  border: none;
  height: 30px;
  width: 30px;
  margin: 0 5px;
  border-radius: 30px;
  font-weight: 800;
}

.scoreboard td {
  background-color: #eee;
  color: #00171f;
  border-bottom: 2px solid #00171f;
  font-size: 18px;
}

.border-light-l {
  border-left: 2px solid #eee;
}

.border-light-r {
  border-right: 2px solid #eee;
}

.border-dark-l {
  border-left: 2px solid #00171f;
}

.border-dark-r {
  border-right: 2px solid #00171f;
}

.footer {
  position: absolute;
  bottom: 0%;
  padding: 10px;
  display: block;
  font-size: 12px;
  color: white;
  text-align: center;
  width: 100%;
}

.links {
  min-height: 100vh;
  background-color: #00171f;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.links h1 {
  font-size: calc(40px + 5vw);
  padding-top: 30px;
  text-align: center;
  color: white;
}

.links .important-i {
  font-size: calc(10px + 1vw);
  color: white;
  font-weight: 500;
  max-width: 1200px;
}

.links-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 20px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

.links-container > * {
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 20px;
  transition-duration: 0.4s;
  text-transform: uppercase;
}

.links-container > * > * {
  color: white;
}

.border-darkorange {
  border: 2px solid #ff4500;
}

.border-darkorange:hover {
  background-color: #ff4500;
}

.border-orange {
  border: 2px solid orange;
}

.border-darkblue {
  border: 2px solid #123c99;
}

.border-darkblue:hover {
  background-color: #123c99;
}

.border-orange:hover {
  background-color: orange;
}

.border-lightblue {
  border: 2px solid #8ad0e7;
}

.border-lightblue:hover {
  background-color: #8ad0e7;
}

.border-lightblue:hover a {
  color: #00171f;
}

.border-white {
  border: 2px solid white;
}

.border-indigo {
  border: 2px solid #4d7a97;
}

.border-indigo:hover {
  background-color: #4d7a97;
}

.border-lightgrey {
  border: 2px solid #ddd;
}

.border-lightgrey:hover {
  background-color: #ddd;
}

.border-white:hover {
  background-color: #fff;
}

.border-white:hover a {
  color: #00171f;
}

.border-lightgrey:hover a {
  color: #00171f;
}

.border-rebeccapurple {
  border: 2px solid rebeccapurple;
}

.border-rebeccapurple:hover {
  background-color: rebeccapurple;
}

.qa-container {
  background-color: #00171f;
  color: white;
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
}

.qa-container div {
  padding: 10px;
}

.question-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-block;
  max-width: 1400px;
  border-bottom: 2px solid white;
  margin-bottom: 20px;
}

.question-info {
  font-weight: 500;
  font-size: calc(20px + 0.75vw);
  color: #ddd;
}

.question {
  font-size: calc(30px + 0.75vw);
  margin: 10px 0;
}

.tags {
  font-size: 18px;
  color: #ddd;
  margin-bottom: 10px;
}

.answer-container {
  max-width: 1400px;
  width: 100%;
  display: block;
}

.answer-input {
  resize: none;
  padding: 10px 20px;
  width: 100%;
  background: none;
  border: 2px solid #fff;
  color: white;
  transition: 0.4s;
}

.answer-input:hover {
  border-radius: 10px;
}

.answer-input::placeholder {
  color: white;
  font-size: 20px;
}

.answer-input[type="text" i] {
  color: white;
  font-size: 20px;
}

.answer-input:focus {
  outline: none;
  border-radius: 10px;
  /* box-shadow: 0px 2px 20px 10px rgba(255, 255, 255, 0.1); */
}

.inner-container {
  border-bottom: 2px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
}

.upvote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .upvote-container > button {
  font-size: 40px;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  position: relative;
  transition: 0.4s all ease;
  outline: none;
  border: none;
  height: 50px;
  background: none;
}

.upvote-container > button:hover {
  transform: translateY(-4px);
  cursor: pointer;
}

.upvote-container > button:focus {
  color: white;
  -webkit-text-stroke: 1px rgb(7, 7, 7);
} */

.content-container {
  flex-grow: 1;
  height: fit-content;
  align-self: start;
}

.answer-details {
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.answer-answer {
  font-size: 16px;
  line-height: 1.75;
}

.button-submit {
  padding: 5px 20px;
  color: white;
  outline: none;
  border: 2px solid #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 20px;
  background: transparent;
  transition: 0.4s all ease;
}

.button-submit:hover {
  background-color: white;
  color: #00171f;
  border-radius: 10px;
  cursor: pointer;
}
@media only screen and (max-width: 775px) {
  .qa-container {
    background-color: #00171f;
    color: white;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;
  }
}
